<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5 main-color">
          {{ $t("Select Parents") }}
        </v-card-title>
        <v-card-text class="my-4">
          <v-form v-model="valid" ref="form">
            <v-autocomplete
              v-model="selectedParents"
              :items="items"
              solo
              small-chips
              :label="$t('Parents')"
              item-text="name"
              item-value="id"
              multiple
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="modal-btn-cancel" @click="closeDialog">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn class="modal-btn-save" @click="assignParentsToDriver()">
            {{ $t("Save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "assignDriverDialog",
  props: ["dialog", "driver_id", "parents_ids"],

  data() {
    return {
      valid: false,
      items: [],
      selectedParents: [],
    };
  },
  methods: {
    getAllParents() {
      this.$http
        .get(this.getApiUrl + "/nedaa/getParents/" + this.driver_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.items = response.data.data;
            this.selectedParents = this.parents_ids ? this.parents_ids : [];
          }
        });
    },
    closeDialog(afterSave = false) {
      this.$emit("closeDialog", afterSave);
    },

    assignParentsToDriver() {
      if (this.valid) {
        this.$http
          .post(
            this.getApiUrl + "/nedaa/assignParents",

            { id: this.driver_id, parents_ids: this.selectedParents },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            this.closeDialog(true);
            console.log(response.data);
          });
      } else {
        this.validate();
      }
    },
  },
  mounted() {
    this.getAllParents();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
@import "@/styles/_modals.scss";
</style>
