<template>
  <div class="my-8 drivers">
    <!-- <h2 class="main-color">{{ $t("All Drivers") }}</h2> -->
    <v-data-table
      :options.sync="options"
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="items"
      item-key="id"
      :show-select="false"
      class="elevation-1 level1 templates text-center"
      text="left"
      hide-default-footer
    >


    <template v-slot:top>
      <v-row class="headerTable-div">
        <v-col md="4" sm="12">
          <h3 class="custom-header main-color">
            {{ $t("All Drivers") }}
          </h3>
        </v-col>
        <v-col md="4" sm="12">
          <label class="search" for="inpt_search">
            <input id="inpt_search" class="input_search" @focus="inputFocus" @blur="inputBlur" type="text"
              @keyup.enter="getAllDrivers(1)" v-model.trim="search" />
    
            <v-icon :title="$t('Search')" @click="getAllDrivers(1)" class="search-icon">search</v-icon>
          </label>
        </v-col>
    
       
      </v-row>
    </template>

      <template v-slot:item.assign_more="{ item }">
        <v-btn @click="openAssingDialog(item.id, item.parents_ids)" icon>
          <v-icon :title="$t('add/edit')">edit</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <div class="text-center my-2">
      <v-pagination
        v-if="length > 0"
        v-model="page"
        :length="length"
        :total-visible="totalVisible"
        circle
      ></v-pagination>
    </div>

    <assignDriverDialog
      :dialog="dialog"
      :driver_id="selectedDriverId"
      :parents_ids="selectedParentsIds"
      v-if="dialog"
      @closeDialog="closeDialog"
    ></assignDriverDialog>
  </div>
</template>

<script>
import assignDriverDialog from "@/components/nedaa/assignDriverDialog.vue";
export default {
  name: "drivers",
  components: { assignDriverDialog },
  data() {
    return {
      dialog: false,
      selectedDriverId: "",
      selectedParentsIds: [],
      loading: false,
      options: {
        itemsPerPage: 15,
      },

      circle: true,
      page: 1,
      length: 1,
      totalVisible: 5,
      headers: [
        {
          text: this.$i18n.t("Driver's Name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: this.$i18n.t("Phone Number"), value: "mobile" },
        { text: this.$i18n.t("National ID"), value: "national_id" },
        { text: this.$i18n.t("Created by"), value: "created_by" },
        { text: this.$i18n.t("Assigned to"), value: "assigned_to" },
        { text: this.$i18n.t("Edit"), value: "assign_more" },
      ],
      items: [],
      search:"",
    };
  },
  watch: {
    page: function () {
      this.getAllDrivers();
    },
  },
  methods: {
    getAllDrivers() {
      this.$http
        .get(this.getApiUrl + "/nedaa/getAllDrivers?search="+this.search+"&&page=" + this.page, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.items = response.data.data.data;
          this.page = response.data.data.current_page;
          this.options.itemsPerPage = response.data.data.per_page;
          this.length = response.data.data.last_page;

          this.items.forEach((item) => {
            item.edit = "";
          });
        });
    },
    openAssingDialog(id, parents_ids) {
      this.dialog = true;
      this.selectedDriverId = id;
      this.selectedParentsIds = parents_ids;
    },
    closeDialog(afterSave) {
      this.dialog = false;
      this.selectedDriverId = "";
      if (afterSave) {
        this.getAllDrivers();
      }
    },
  },
  mounted() {
    this.getAllDrivers();
  },
};
</script>

<style></style>
